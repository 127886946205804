import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://doctor-web-backend-production.up.railway.app/api';

const api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

// Response Interceptor
api.interceptors.response.use(
    response => response,
    error => {
        let errorMessage = 'Xatolik yuz berdi.';
        
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    errorMessage = 'Malumot topilmadi.';
                    break;
                case 500:
                    errorMessage = 'Server xatosi.';
                    break;
                default:
                    errorMessage = error.response.data.message || 'Xatolik yuz berdi.';
            }
        } else if (error.request) {
            errorMessage = 'Serverga ulanib bolmadi.';
        }
        
        return Promise.reject(errorMessage);
    }
);

// API fonksiyonları - URL'leri backend ile eşleştirdik
export const apiService = {
    // Makaleler
    getArticles: () => api.get('/article/'),
    getArticle: (id) => api.get(`/article/${id}/`),
    
    // Sorular
    getQuestions: () => api.get('/question/'),
    createQuestion: (data) => api.post('/question/', data),
    
    // Hasta Görüşleri
    getTestimonials: () => api.get('/testimonial/'),
    createTestimonial: (data) => api.post('/testimonial/', data),
    
    // İletişim
    createContact: (data) => api.post('/contact/', data),
    
    // Hakkımda
    getAbout: () => api.get('/about/'),
    
    // Tadqiqotlar (Research)
    getResearch: () => api.get('/research/'),
    getResearchById: (id) => api.get(`/research/${id}/`),
    getResearchByCategory: (category) => api.get(`/research/?category=${category}`),
};
