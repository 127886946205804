import { useState, useEffect } from 'react';
import { apiService } from '../services/api';

export default function QA() {
  const [formData, setFormData] = useState({
    name: '',
    age: '',
    gender: 'UNKNOWN',
    region: '',
    email: '',
    phone: '',
    question: '',
    image: null
  });
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [visibleQuestions, setVisibleQuestions] = useState(4);
  const [searchTerm, setSearchTerm] = useState('');

  // Cevaplanmış soruları çek
  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await apiService.getQuestions();

        console.log('Questions Response:', response.data);

        setQuestions(response.data);
      } catch (err) {
        console.error('Savollar yuklanirishda xatolik yuz berdi:', err);
      }
    };
    fetchQuestions();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Yuborilgan maʼlumotlar:', formData);
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      await apiService.createQuestion(formData);
      setSuccess(true);
      setFormData({ name: '', age: '', region: '', email: '', phone: '', question: '' });
    } catch (err) {
      setError('Savolni yuborishda xatolik yuz berdi. Iltimos, qayta urinib koʻring.');
    } finally {
      setLoading(false);
    }
  };

  // Filtrelenmiş soruları al
  const filteredQuestions = questions
    .filter(q => q.is_answered)
    .filter(q => 
      q.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
      q.answer.toLowerCase().includes(searchTerm.toLowerCase())
    );

  // Görünür soruları al
  const currentQuestions = filteredQuestions.slice(0, visibleQuestions);
  const hasMoreQuestions = filteredQuestions.length > visibleQuestions;

  // Daha fazla göster
  const handleLoadMore = () => {
    setVisibleQuestions(prev => prev + 4);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white pt-24 px-4">
      <div className="container mx-auto max-w-7xl">
        {/* Header Section - Yeni Tasarım */}
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
            Savol va Javob
          </h1>
          <p className="text-gray-600 max-w-2xl mx-auto text-lg leading-relaxed">
            Sizni qiziqtirgan barcha savollarga javob olishingiz mumkin. 
            Savolingizni qoldiring va biz tez orada javob beramiz.
          </p>
        </div>

        <div className="grid lg:grid-cols-5 gap-8">
          {/* Form Section - Sol taraf (3 kolon) */}
          <div className="lg:col-span-3">
            <div className="bg-white rounded-3xl shadow-xl p-8 backdrop-blur-lg bg-opacity-95">
              <h2 className="text-2xl font-bold mb-8 text-gray-800 flex items-center">
                <svg className="w-6 h-6 mr-3 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                Savol berish
              </h2>

              {/* Success ve Error mesajları için yeni tasarım */}
              {success && (
                <div className="mb-6 p-4 bg-green-50 border-l-4 border-green-500 rounded-r-lg">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <svg className="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                      </svg>
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-green-700">
                        Savolingiz muvaffaqiyatli yuborildi. Iloji boricha tezroq javob beriladi.
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {/* Form alanları için yeni stil */}
              <form onSubmit={handleSubmit} className="space-y-6">
                {/* Mevcut form alanları aynı kalacak ama yeni stiller eklenecek */}
                {/* Input ve select alanları için hover efekti ve daha yumuşak geçişler */}
                <style jsx>{`
                  input, select, textarea {
                    transition: all 0.3s ease;
                  }
                  input:hover, select:hover, textarea:hover {
                    border-color: #93C5FD;
                  }
                `}</style>
                
                <div>
                  <label className="block text-gray-700 mb-2">Ismi-Familya</label>
                  <input
                    type="text"
                    className="w-full p-3 border rounded"
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  />
                </div>

                <div>
                  <label className="block text-gray-700 mb-2">Manzil</label>
                  <input
                    type="text"
                    className="w-full p-3 border rounded"
                    value={formData.region}
                    onChange={(e) => setFormData({ ...formData, region: e.target.value })}
                  />
                </div>

                <div>
                  <label className="block text-gray-700 mb-2">Telefon raqamingiz (siz bilan bog`lanish va SMS yuborish uchun):</label>
                  <input
                    type="tel"
                    className="w-full p-3 border rounded"
                    value={formData.phone}
                    onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                    required
                  />
                </div>

                <div>
                  <label className="block text-gray-700 mb-2">Javob uchun email</label>
                  <input
                    type="email"
                    className="w-full p-3 border rounded"
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  />
                </div>

                <div>
                  <label className="block text-gray-700 mb-2">Yoshi</label>
                  <input
                    type="number"
                    className="w-full p-3 border rounded"
                    value={formData.age}
                    onChange={(e) => setFormData({ ...formData, age: e.target.value })}
                  />
                </div>

                <div>
                  <label className="block text-gray-700 mb-2">Erkak / Ayol</label>
                  <select
                    className="w-full p-3 border rounded"
                    value={formData.gender}
                    onChange={(e) => setFormData({ ...formData, gender: e.target.value })}
                  >
                    <option value="UNKNOWN">Tanlang</option>
                    <option value="MALE">Erkak</option>
                    <option value="FEMALE">Ayol</option>
                  </select>
                </div>

                <div>
                  <label className="block text-gray-700 mb-2">Rasm yuklash</label>
                  <input
                    type="file"
                    className="w-full p-3 border rounded"
                    onChange={(e) => setFormData({ ...formData, image: e.target.files[0] })}
                  />
                </div>

                <div>
                  <label className="block text-gray-700 mb-2">Mavzu</label>
                  <input
                    type="text"
                    className="w-full p-3 border rounded"
                    value={formData.subject}
                    onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
                  />
                </div>

                <div>
                  <label className="block text-gray-700 mb-2">Savol</label>
                  <textarea
                    className="w-full p-3 border rounded"
                    rows={4}
                    value={formData.question}
                    onChange={(e) => setFormData({ ...formData, question: e.target.value })}
                    required
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="mt-4 bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700 transition-colors"
                >
                  Yuborish
                </button>
              </form>
            </div>
          </div>

          {/* Sağ taraf - Cevaplanmış Sorular (2 kolon) */}
          <div className="lg:col-span-2">
            <div className="sticky top-24">
              <div className="bg-white rounded-3xl shadow-xl p-8">
                <h2 className="text-2xl font-bold mb-6 text-gray-800 flex items-center">
                  <svg className="w-6 h-6 mr-3 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                  </svg>
                  Tez-tez so'raladigan savollar
                </h2>

                {/* Arama kutusu */}
                <div className="relative mb-6">
                  <input
                    type="text"
                    placeholder="Savollarni qidirish..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full pl-10 pr-4 py-3 rounded-xl border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                  <svg className="w-5 h-5 text-gray-400 absolute left-3 top-3.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                </div>

                {/* Sorular listesi için yeni tasarım */}
                <div className="space-y-4 max-h-[600px] overflow-y-auto custom-scrollbar pr-2">
                  {currentQuestions.map((question) => (
                    <div key={question.id} 
                         className="bg-gray-50 rounded-xl p-6 transition-all duration-300 hover:shadow-md hover:bg-gray-100">
                      <div className="space-y-3">
                        <p className="font-medium text-gray-800">{question.question}</p>
                        <p className="text-gray-600 pl-4 border-l-2 border-blue-500">{question.answer}</p>
                        <div className="flex items-center text-sm text-gray-500 mt-2">
                          <span className="font-medium">{question.name}</span>
                          <span className="mx-2">•</span>
                          <time>{new Date(question.created_at).toLocaleDateString('uz-UZ')}</time>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Daha fazla göster butonu */}
                {hasMoreQuestions && (
                  <button
                    onClick={handleLoadMore}
                    className="w-full mt-6 py-3 px-4 bg-gray-100 text-gray-700 rounded-xl hover:bg-gray-200 transition-all duration-300 flex items-center justify-center"
                  >
                    Ko'proq ko'rish
                    <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
