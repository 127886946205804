import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { apiService } from '../services/api';
import { ArticleSkeleton, TestimonialSkeleton, FAQSkeleton } from '../components/LoadingSkeletons';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getImageUrl } from '../utils/helpers';

export default function Home() {
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [articles, setArticles] = useState([]);
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [questionsResponse, articlesResponse, testimonialsResponse] = await Promise.all([
          apiService.getQuestions(),
          apiService.getArticles(),
          apiService.getTestimonials()
        ]);

        console.log('Articles Response:', articlesResponse.data);

        const answeredQuestions = questionsResponse.data
          .filter(q => q.is_answered)
          .slice(0, 8);
        setQuestions(answeredQuestions);

        const latestArticles = articlesResponse.data
          .slice(0, 3);
        setArticles(latestArticles);

        const approvedTestimonials = testimonialsResponse.data
          .filter(t => t.is_approved)
          .slice(0, 10);
        setTestimonials(approvedTestimonials);

      } catch (err) {
        console.error('Maʼlumotlarni yuklashda xatolik yuz berdi:', err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const testimonialSliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="pt-16">
      {/* Hero Bölümü */}
      <header className="relative bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400 text-white py-20 md:py-32 overflow-hidden">
        {/* Animasyonlu arka plan desenleri */}
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-blue-600 opacity-30">
            <svg className="absolute left-0 top-0 h-full w-full" viewBox="0 0 100 100" preserveAspectRatio="none">
              <path d="M0,0 L100,0 L100,100 L0,100 Z" fill="url(#hero-gradient)" />
            </svg>
          </div>
          {/* Dalgalı şekiller */}
          <div className="absolute bottom-0 left-0 right-0">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="w-full">
              <path fill="rgba(255,255,255,0.1)" fillOpacity="1" d="M0,96L48,112C96,128,192,160,288,160C384,160,480,128,576,112C672,96,768,96,864,112C960,128,1056,160,1152,160C1248,160,1344,128,1392,112L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
            </svg>
          </div>
        </div>

        <div className="container mx-auto px-4 relative">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            {/* Sol taraf - Metin içeriği */}
            <div className="text-left">
              <div className="inline-block bg-blue-700 bg-opacity-50 rounded-lg px-4 py-2 mb-6">
                <span className="text-blue-100">30+ yillik tajriba</span>
              </div>
              <h1 className="text-4xl md:text-6xl font-bold mb-6 leading-tight">
                Yuragingiz
                <span className="block text-transparent bg-clip-text bg-gradient-to-r from-white to-blue-200">
                  Ishonchli qo'llarda
                </span>
              </h1>
              <p className="text-xl mb-8 text-blue-100 max-w-lg">
                Biz zamonaviy tibbiy texnologiyamiz va mutaxassis xodimlarimiz bilan yurak sog'ligingiz uchun shu yerdamiz.
              </p>
              <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                <Link 
                  to="/contact" 
                  className="group relative inline-flex items-center justify-center px-8 py-3 bg-white text-blue-600 font-semibold rounded-full overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300"
                >
                  <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-blue-600 rounded-full group-hover:w-full group-hover:h-full"></span>
                  <span className="relative group-hover:text-white transition-colors duration-300">
                    Biz bilan bog'laning
                    <svg className="w-5 h-5 ml-2 inline-block" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                  </span>
                </Link>
                <Link 
                  to="/articles" 
                  className="group inline-flex items-center justify-center px-8 py-3 border-2 border-white text-white rounded-full hover:bg-white hover:text-blue-600 transition-all duration-300"
                >
                  Maqolalar
                </Link>
              </div>
            </div>

            {/* Sağ taraf - Görsel ve istatistikler */}
            <div className="relative hidden md:block">
              <div className="relative z-10 bg-white bg-opacity-10 backdrop-blur-lg rounded-2xl p-4 max-w-md mx-auto">
                <img 
                  src="/doctor-hero.png" 
                  alt="Dr. Ali Valiyev" 
                  className="w-full h-[400px] object-cover object-center rounded-xl"
                />
                {/* Floating stats cards */}
                <div className="absolute -left-8 top-1/4 bg-white p-3 rounded-xl shadow-lg">
                  <div className="flex items-center space-x-3">
                    <div className="p-2 bg-blue-100 rounded-lg">
                      <svg className="w-5 h-5 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </div>
                    <div>
                      <div className="text-sm text-gray-600">Operatsiya</div>
                      <div className="text-lg font-bold text-blue-600">1000+</div>
                    </div>
                  </div>
                </div>
                <div className="absolute -right-8 bottom-1/4 bg-white p-3 rounded-xl shadow-lg">
                  <div className="flex items-center space-x-3">
                    <div className="p-2 bg-blue-100 rounded-lg">
                      <svg className="w-5 h-5 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </div>
                    <div>
                      <div className="text-sm text-gray-600">Baxtli Bemor</div>
                      <div className="text-lg font-bold text-blue-600">5000+</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
  
      {/* Öne Çıkan Makaleler */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center mb-12">
            <h2 className="text-4xl font-bold">Tavsiya Etilgan Maqolalar</h2>
            <Link 
              to="/articles" 
              className="text-blue-600 hover:text-blue-800 flex items-center group"
            >
              Barcha Maqolalar
              <svg 
                className="w-5 h-5 ml-2 transform group-hover:translate-x-1 transition-transform" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth="2" 
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </Link>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {loading ? (
              [...Array(3)].map((_, index) => (
                <ArticleSkeleton key={index} />
              ))
            ) : articles.length > 0 ? (
              articles.map((article) => (
                <Link 
                  key={article.id} 
                  to={`/articles/${article.id}`}
                  className="bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 overflow-hidden"
                >
                  <div className="relative h-48">
                    <img 
                      src={getImageUrl(article.image)}
                      alt={article.title}
                      className="w-full h-full object-cover"
                    />
                    {article.category && (
                      <div className="absolute top-4 right-4 bg-blue-600 text-white text-sm px-3 py-1 rounded-full">
                        {article.category}
                      </div>
                    )}
                  </div>
                  <div className="p-6">
                    <h3 className="text-xl font-semibold mb-3 line-clamp-2">{article.title}</h3>
                    <p className="text-gray-600 mb-4 line-clamp-3">
                      {article.summary || article.content.slice(0, 150)}...
                    </p>
                    <div className="flex justify-between items-center">
                      <span className="text-blue-600 font-semibold group-hover:text-blue-800 transition-colors">
                        Batafsil →
                      </span>
                      <span className="text-sm text-gray-500">
                        {new Date(article.created_at).toLocaleDateString('tr-TR')}
                      </span>
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <div className="col-span-3 text-center text-gray-500 py-8">
                Hozircha maqolalar yo‘q.
              </div>
            )}
          </div>
        </div>
      </section>
  
      {/* Öne Çıkan Hizmetler */}
      <section className="py-16 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">Bizning Mutaxassislik Sohalarimiz</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                title: "Yurak Jarrohligi",
                icon: "❤️",
                description: "Zamonaviy usullar bilan yurak operatsiyalari..."
              },
              {
                title: "Qon Tomir Jarrohligi",
                icon: "🫀",
                description: "Qon tomir okklyuziyasini davolash usullari..."
              },
              {
                title: "Minimal Invaziv Jarrohlik",
                icon: "🔬",
                description: "Kichik kesmalar bilan katta operatsiyalar..."
              }
            ].map((service, index) => (
              <div key={index} className="bg-gray-50 p-6 rounded-xl hover:shadow-lg transition-shadow duration-300">
                <div className="text-4xl mb-4">{service.icon}</div>
                <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
                <p className="text-gray-600">{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
  
      {/* İstatistikler */}
      <section className="py-16 bg-blue-600 text-white">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8 text-center">
            {[
              { number: "30+", label: "Yillik Tajriba" },
              { number: "1000+", label: "Muvaffaqiyatli Operatsiya" },
              { number: "50+", label: "Ilmiy Nashr" },
              { number: "5000+", label: "Baxtli Bemor" }
            ].map((stat, index) => (
              <div key={index}>
                <div className="text-3xl md:text-4xl font-bold mb-2">{stat.number}</div>
                <div className="text-blue-100">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>
      </section>
  
      {/* Hasta Yorumları Slider */}
      <section className="py-16 bg-white">
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center mb-12">
            <h2 className="text-3xl md:text-4xl font-bold">Bemor Izohlari</h2>
            <Link 
              to="/testimonials" 
              className="text-blue-600 hover:text-blue-800 flex items-center group"
            >
              Barcha Izohlar
              <svg 
                className="w-5 h-5 ml-2 transform group-hover:translate-x-1 transition-transform" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
              </svg>
            </Link>
          </div>

          {loading ? (
            <div className="grid md:grid-cols-3 gap-6">
              {[...Array(3)].map((_, index) => (
                <TestimonialSkeleton key={index} />
              ))}
            </div>
          ) : testimonials.length > 0 ? (
            <Slider {...testimonialSliderSettings} className="testimonial-slider -mx-4">
              {testimonials.map((testimonial) => (
                <div key={testimonial.id} className="px-4">
                  <div className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 h-full border border-gray-100">
                    <div className="flex items-center mb-4">
                      <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
                        <span className="text-blue-600 font-semibold">
                          {testimonial.name.charAt(0).toUpperCase()}
                        </span>
                      </div>
                      <div className="ml-4">
                        <h3 className="font-semibold">{testimonial.name}</h3>
                        <div className="flex text-yellow-400">
                          {[...Array(5)].map((_, i) => (
                            <svg
                              key={i}
                              className={`w-4 h-4 ${i < testimonial.rating ? 'text-yellow-400' : 'text-gray-300'}`}
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                            </svg>
                          ))}
                        </div>
                      </div>
                    </div>
                    <p className="text-gray-600 italic line-clamp-4">
                      "{testimonial.content}"
                    </p>
                    <div className="mt-4 text-sm text-gray-500 text-right">
                      {new Date(testimonial.created_at).toLocaleDateString('tr-TR')}
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="text-center text-gray-500 py-8">
              Hozircha bemorning izohlari yo'q.
            </div>
          )}
        </div>
      </section>
  
      {/* Modern Tıbbi Yaklaşım */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div>
              <h2 className="text-3xl md:text-4xl font-bold mb-4">Zamonaviy tibbiy yondashuv</h2>
              <p className="text-gray-600 mb-6">
              Eng so'nggi texnologik o'zgarishlarni kuzatib, biz bemorlarimizga eng yaxshi davolash usullarini taklif qilamiz.
              </p>
              <Link to="/about" className="text-blue-600 font-semibold hover:text-blue-800">
              Batafsil ma'lumot →
              </Link>
            </div>
            <div className="aspect-w-16 aspect-h-9 bg-gray-200 rounded-lg">
              {/* Video veya görsel yerleştirilecek */}
            </div>
          </div>
        </div>
      </section>
  
      {/* Sık Sorulan Sorular */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center mb-12">
            <h2 className="text-3xl md:text-4xl font-bold">Tez-tez so'raladigan savollar</h2>
            <Link 
              to="/qa" 
              className="text-blue-600 hover:text-blue-800 flex items-center group"
            >
              Hammasini koʻrish
              <svg 
                className="w-5 h-5 ml-2 transform group-hover:translate-x-1 transition-transform" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth="2" 
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </Link>
          </div>

          {loading ? (
            <div className="grid md:grid-cols-2 gap-6">
              {[...Array(2)].map((_, index) => (
                <FAQSkeleton key={index} />
              ))}
            </div>
          ) : questions.length > 0 ? (
            <Slider {...sliderSettings} className="faq-slider -mx-4">
              {questions.map((faq, index) => (
                <div key={index} className="px-4">
                  <div className="bg-white p-6 rounded-xl shadow-lg h-full">
                    <div className="flex items-start mb-4">
                      <div className="w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center mr-3 mt-1 flex-shrink-0">
                        <svg className="w-4 h-4 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </div>
                      <div>
                        <h3 className="font-semibold text-lg mb-2">Savol:</h3>
                        <p className="text-gray-600 mb-4">{faq.question}</p>
                        <div className="border-t pt-4">
                          <h3 className="font-semibold text-lg mb-2">Javob:</h3>
                          <p className="text-gray-600">{faq.answer}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="text-center text-gray-500 py-8">
              Hozircha hech qanday savolga javob yo'q.
            </div>
          )}
          
        </div>
      </section>
  
      {/* İletişim CTA */}
      <section className="py-16 bg-gradient-to-r from-blue-600 to-blue-800 text-white">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-6">Sog'ligingiz uchun biz bilan bog'laning</h2>
          <p className="text-xl text-blue-100 mb-8">
            Savollaringiz uchun har doim shu yerdamiz
          </p>
          <Link 
            to="/contact"
            className="inline-block bg-white text-blue-600 px-8 py-3 rounded-full font-semibold hover:bg-blue-50 transition-colors duration-300"
          >
            Biz bilan bog'laning
          </Link>
        </div>
      </section>
    </div>
  );
}
