import { useState, useEffect } from 'react';
import { apiService } from '../services/api';

export default function Testimonials() {
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newTestimonial, setNewTestimonial] = useState({
    name: '',
    content: '',
    rating: 5
  });

  const fetchTestimonials = async () => {
    try {
      const response = await apiService.getTestimonials();

      console.log('Testimonials Response:', response.data);
      
      const approvedTestimonials = response.data.filter(t => t.is_approved);
      setTestimonials(approvedTestimonials);
    } catch (err) {
      setError('Bemor fikrlarini yuklashda xatolik yuz berdi.');
      console.error('Bemor fikrlarini yuklashda xatolik yuz berdi:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await apiService.createTestimonial(newTestimonial);
      setNewTestimonial({ name: '', content: '', rating: 5 });
      alert('Fikringiz muvaffaqiyatli yuborildi! Tasdiqlangandan keyin e\'lon qilinadi.');
      await fetchTestimonials();
    } catch (err) {
      alert('Fikringizni yuborishda xatolik yuz berdi.');
      console.error('Fikringizni yuborishda xatolik yuz berdi:', err);
    }
  };

  const StarRating = ({ rating, setRating, disabled = false }) => {
    return (
      <div className="flex items-center space-x-1">
        {[1, 2, 3, 4, 5].map((star) => (
          <button
            key={star}
            type="button"
            disabled={disabled}
            onClick={() => !disabled && setRating(star)}
            className={`${
              disabled ? 'cursor-default' : 'cursor-pointer hover:scale-110'
            } transition-transform`}
          >
            <svg
              className={`w-8 h-8 ${
                star <= (disabled ? rating : newTestimonial.rating)
                  ? 'text-yellow-400'
                  : 'text-gray-300'
              }`}
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
          </button>
        ))}
      </div>
    );
  };

  if (loading) {
    return (
      <div className="pt-20 px-4 min-h-screen">
        <div className="container mx-auto text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="pt-20 px-4 min-h-screen bg-gray-50">
      <div className="container mx-auto">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4">Bemor Fikrlari</h1>
          <p className="text-gray-600 max-w-2xl mx-auto">
          Biz bu yerda qimmatli bemorlarimizning tajribalari va fikrlarini baham ko'ramiz.
          Siz yuborgan sharhlar tasdiqlanganidan keyin e'lon qilinadi.
          </p>
        </div>

        {error && (
          <div className="bg-red-100 text-red-700 p-4 rounded-lg mb-6 text-center">
            {error}
          </div>
        )}

        {/* Görüş Listesi */}
        <div className="grid md:grid-cols-2 gap-6 mb-12">
          {testimonials.map((testimonial) => (
            <div key={testimonial.id} className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow">
              <div className="flex items-center mb-4">
                <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
                  <span className="text-blue-600 font-semibold">
                    {testimonial.name.charAt(0).toUpperCase()}
                  </span>
                </div>
                <div className="ml-4">
                  <h3 className="font-semibold">{testimonial.name}</h3>
                  <span className="text-sm text-gray-500">
                    {new Date(testimonial.created_at).toLocaleDateString('tr-TR')}
                  </span>
                </div>
              </div>
              <div className="mb-3">
                <StarRating rating={testimonial.rating} disabled={true} />
              </div>
              <p className="text-gray-600">{testimonial.content}</p>
            </div>
          ))}
        </div>

        {testimonials.length === 0 && !error && (
          <div className="text-center text-gray-500 py-12 bg-white rounded-lg shadow mb-12">
            <p>Hozircha bemorning fikri yo'q.</p>
          </div>
        )}

        {/* Yeni Görüş Formu */}
        <div className="max-w-2xl mx-auto bg-white p-8 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold mb-6">Fikringizni bildirish</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2" htmlFor="name">
                Isım Familiya
              </label>
              <input
                id="name"
                type="text"
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                value={newTestimonial.name}
                onChange={(e) => setNewTestimonial({...newTestimonial, name: e.target.value})}
                required
              />
            </div>
            
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">
                Sizning baholashingiz
              </label>
              <StarRating 
                rating={newTestimonial.rating} 
                setRating={(rating) => setNewTestimonial({...newTestimonial, rating})}
              />
            </div>

            <div className="mb-6">
              <label className="block text-gray-700 mb-2" htmlFor="content">
                Fikringiz
              </label>
              <textarea
                id="content"
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                rows={4}
                value={newTestimonial.content}
                onChange={(e) => setNewTestimonial({...newTestimonial, content: e.target.value})}
                required
              ></textarea>
            </div>
            
            <button
              type="submit"
              className="w-full bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors"
            >
              Fikringizni yuborish
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
